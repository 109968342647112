export type ExifLib = {
	getData(url: string, callback: any): any;
	getTag(img: any, tag: any): any;
	getAllTags(img: any): any;
	pretty(img: any): string;
	readFromBinaryFile(file: any): any;
};

let processor: ExifLib;

const Exif = async (): Promise<ExifLib> => {
	if (processor != null) {
		return processor;
	}
	processor = await import(/* webpackChunkName: "exif-js" */ 'exif-js');
	return processor;
};
export default Exif;
