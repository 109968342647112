import { BeneficiaryType, Gender, ParticipantIdType, PolicyCustomerType, PaymentMethod, PayType, PolicyDetailStatus, PolicyTempStatus } from '@my-types';

enum AgreementStatus {
	DEFINE = 1,
	APPLY = 2,
	ARPPOVE = 3,
	REJECT = 4,
	SHUTDOWN = 5
}
enum PolicyStatus {
	QUOTE = 1,
	ISSUED = 2,
	WAIT_FOR_PAYMENT = 3,
	ADVISE = 5,
	WAIT_COMPLETE = 5,
	DELETED_ADVISE = 6,
	PAID = 7,
	ISSUING = 8,
	DELETED_QUOTE = 9,
	INSURE_FAILED = 10,
	WAIT_FOR_SUBMIT = 11,
	PAY_FAILED = 87,
	INVALID = 88,
	WITHDRAWN = 96,
	DECLINE = 97,
	CANCELLED = 98,
	TERMINATED = 99
}
enum TaxType {
	CHINA = 1,
	NOT_CHINA = 2,
	BOTH = 3
}
enum CurrencySign {
	CNY = '¥'
}
enum ProductFunctionType {
	NONE = '0',	
	ADVICE = '1',
	QUOTE = '2',
	BOTH = '3'
}
enum AccountType {
	ADMIN = '1',
	USER = '2'
}
/**
 * 账号状态
 */
enum AccountEnabled {
	INVALID = '0', //失效
	VALID = '1', //有效
	WAIT_APPROVE = '2', //待审核
	WAIT_GRANT_AUTH = '3', //待授权
	FAIL_APPROVE = '4', //不通过
	LOCK = '5', //锁定
}
enum TenantType {
	INSURANCE_COMPANY = '1',
	BROKER = '2',
	AGENT = '3',
	VIRTUAL = '4',
	SYSTEM_OWNER = '5'
}
enum TenantIds {
	SYSTEM_OWNER = '1',
	DEMO_BROKER = '2',
	DEMO_AGENT = '3',
	DEMO_INSURANCE_COMPANY = '5',
	VIRTUAL = '4'
}
enum PolicyPropertyNames {
	ERROR_DESCRIPTION = 'extensionData.errorsDescription',
	HEALTH_NOTICE_SERIA_NO = 'extensionData.healthNoticeSeriaNo',
	RESEND_FLAG = 'extensionData.reSendFlag',
	WIZARD_STEP_NAME = 'extensionData.step',
	FORCE_CREATE_FLAG = 'extensionData.forceCreate',
	CALCULATION_FLAG = 'extensionData.calculatePassed',
	ILLUSTRATION = 'extensionData.illustration',
	// CLPCP_STATUS = 'extensionData.status',
	DETAIL_STATUS = 'extensionData.status',
	TIPS = 'extensionData.tips',
	NEED_SYNC = 'extensionData.needSync',
	ROOT_URL_FROM = 'extensionData.rootUrlFrom',
	URL_FROM = 'extensionData.urlFrom',
	PARCEL = 'extensionData.parcel',
	OPENID = 'extensionData.openid',
	READ_DECLARATION = 'extensionData.readDeclaration',
	//阅读风险披露
	READ_RISK = 'extensionData.readRisk',
	PAYMENT_METHOD = 'extensionData.paymentInfo.paymentMethod',
	//点击全部为否时，该字段清楚
	UW_RESULT_TYPE = 'extensionData.uwResultType',
	PARTICIPANTS_FLAG = 'extensionData.participantsChange',
	//最小签名时间,用于隔天签名时判断
	MIN_SIGN_DATE = 'extensionData.minSignDate',
	TEMP_STATUS = 'extensionData.tempStatus',
	// 核保信息
	UW_MESSAGE = 'extensionData.uwMessage',
	//域名信息
	HOST_NAME = 'extensionData.hostName',
}
enum SalesArea {
	UNCONCERNED = '99999999'
}

enum CoverageType {
	MAIN = 'M',
	RIDER = 'R'
}

enum ImageFileType {
	JPG = 'jpg',
	JPEG = 'jpeg',
	PNG = 'png'
}

export default {
	// 异常
	FETCH_ERROR: 'FETCH_ERROR',
	// 认证
	AUTH_KEY: 'Authorization',
	CSMD_TOKEN_KEY: 'x-csmd-token',
	CSMD_DELEGATED_KEY: 'x-csmd-delegated',
	// 版本
	CSMS_APP_VER_KEY: 'x-csms-app-ver',
	BACKEND_VER_STORAGE_KEY: 'backend-ver',
	// 主题
	THEME_KEY: 'name',
	THEME_COLOR_KEY: 'themeColor',
	// 账户
	ACCOUNT_KEY: 'Account',
	DETAIL_KEY: 'GO_DETAIL',
	ACCOUNT_SIGN_KEY: 'Signin',
	SHORTCUTS_FLOATING_KEY: 'shortcutsFloating',
	TRUST_KEY_KEY: 'TrustKey',
	//登入的url
	ACCOUNT_SIGN_IN_URL: 'AccountSigninUrl',
	AGENT_LUCKY_DRAW:'AgentLuckDraw',
	/** 从哪个入口登录的, 专指登录页. 不同租户可能有不同登录首页. 默认登录首页不会记录 */
	ACCOUNT_SIGN_IN_FROM: 'AccountSigninFrom',
	/** 从北京海力的登录首页进入, 值与租户代码必须保持一致 */
	ACCOUNT_SIGN_IN_FROM_BJHL: 'BJHL',
	// 格式
	STANDARD_DATETIME_FORMAT: 'YYYY/MM/DD HH:mm:ss',
	STANDARD_DATETIME_FORMAT_WITH_DEFAULT_TIME: 'YYYY/MM/DD 00:00:00',
	STANDARD_DATE_FORMAT: 'YYYY/MM/DD',
	W3C_DATETIME_FORMAT: 'YYYY-MM-DD HH:mm:ss',
	W3C_DATE_FORMAT: 'YYYY-MM-DD',
	// 保单属性
	POLICY_PROPERTIES: PolicyPropertyNames,
	// 租户序列号
	TENANT_IDS: TenantIds,
	// 租户类型
	TENANT_TYPE: TenantType,
	// 账户类型
	ACCOUNT_TYPE: AccountType,
	ACCOUNT_ENABLED: AccountEnabled,
	// 产品功能类型
	PRODUCT_FUNC_TYPE: ProductFunctionType,
	AGREEMENT_STATUS: AgreementStatus,
	// 保单状态
	POLICY_STATUS: PolicyStatus,
	POLICY_DETAIL_STATUS: PolicyDetailStatus,
	POLICY_TEMP_STATUS: PolicyTempStatus,
	// POLICY_STATUS_CLPCP: CLPCPPolicyStatus,
	PAYMENT_METHOD: PaymentMethod,
	PAY_TYPE: PayType,
	ID_TYPE: ParticipantIdType,
	TAX_TYPE: TaxType,
	CUSTOMER_TYPE: PolicyCustomerType,
	BENEFICIARY_TYPE: BeneficiaryType,
	GENDER: Gender,
	CURRENCY_SIGN: CurrencySign,
	SALES_AREA: SalesArea,
	COVERAGE_TYPE: CoverageType,
	//最大日期
	MAX_DATE: '9999/12/31 00:00:00',
	ADVICE_DATA: 'AdviceData',
	GLUTTON_TRACE_NO: 'GluttonTraceNo',
	//允许的图片类型
	IMAGE_FILE_TYPE: ImageFileType,
	/**中国大陆 */
	CHINA_LANDMASS: ['CHN'],
	//@ts-ignore
	STS_FOLDER: `csms2_${process.env.REACT_APP_ENV_NAME.toLowerCase()}`,
	STS_SIGNATURE_EXPIRES: 1800,
	OPEN_ID:'openid',

};
