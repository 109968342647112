import { ImageCompressorLib } from '@my-types';

let processor: ImageCompressorLib;

const ImageCompressor = async (): Promise<ImageCompressorLib> => {
	if (processor != null) {
		return processor;
	}
	await import(/* webpackChunkName: "image-compressor" */ 'image-compressor');
	processor = (window as any).ImageCompressor as ImageCompressorLib;
	return processor;
};
export default ImageCompressor;
