import { asHostBy } from './host-by';
/**
 * 强类型限制, 增加属性必须加上类型限制以及readonly
 * 如果没有显式定义类型, 则其他文件引用后无法提示语法
 */
type APIS = {

	//MEJ

	readonly POSTER_TOKEN: string;
	readonly POSTER_LIST_AGENT: string;
	readonly POSTER_LOAD_BY_ID: string;
	readonly POSTER_ISUSEQRCODE_BY_ID: string;
	readonly LOAD_FIRST_CHANNEL: string;
	readonly ACCOUNT_FOR_AGENT_LOAD: string;
	readonly MEJ_SIGNIN: string;
	readonly HMB_SIGNIN: string;

	readonly MEJ_SIGNUP: string;
	readonly MEJ_VALIDATE: string;
	readonly MEJ_SMS: string;
	readonly HMB_SMS: string;
	readonly MEJ_FORGET: string
	readonly MEJ_ORGAN: string
	readonly MEJ_TENANT_CONFIGURATION: string
	readonly PRODUCT_DISCLOSURE: string
	readonly COOPERATIVE_INSURER_DISCLOSURE: string
	readonly TENANT_CONFIGURATION_LIST: string
	readonly PRODUCT_LIST_TENANT_AVAILABLE: string
	readonly PRODUCT_LIST_KEYWORD: string,
	readonly PRODUCT_LIST_BYCHANEL: string,
	readonly PRODUCT_LIST_KEYWORD_LOGIN: string,
	readonly QUERY_PRODUCT_TAG: string,
	readonly AUTH_CHANGEMOLIE: string,
	readonly AUTH_CHANGEMOLIE_HMB: string,
	readonly DISABLE_ACCOUNTNAME: string,
	readonly UPDATE_ACCOUNT: string,
	readonly HMB_UPDATE_ACCOUNT: string,
	readonly ACCOUNT_MASK: string,
	readonly CHECK_VALIDATIONCODE: string,
	readonly QUERY_PERSONAL: string,
	readonly ACCOUNT_AVATAR: string;
	readonly ACCOUNT_AVATAR_HMB: string;
	readonly ACCOUNT_BIND_ORG: string;

	readonly AUTH_RETRIEVE: string;
	readonly AUTH_SIGNUP: string;
	readonly AUTH_SIGNUP_V2: string;
	readonly AUTH_TRUST_SIGNIN: string;
	readonly AUTH_SIGNIN: string;
	readonly AUTH_SIGNIN_V2: string;
	readonly AUTH_FORGET_PWD: string;
	readonly AUTH_REGISTER: string;
	readonly AUTH_REGISTER2C: string;
	readonly AUTH_SIGNOUT: string;
	readonly AUTH_CHANGE_PWD: string;
	readonly AUTH_SMS_SIGNIN: string;
	readonly AUTH_CHANGE_MOLIE: string;
	readonly AUTH_DISABLE: string;
	readonly AUTH_VALIDATE_UNREADAGREEMENT: string;


	readonly AVATAR_UPLOAD: string;
	readonly CHANGE_PWD: string;
	readonly DIRECT_ASK: string;

	
	
	
	
	
	
	
	

	readonly TENANT_REGISTER_APPLY: string;
	readonly TENANT_REGISTER_LIST: string;
	readonly TENANT_REGISTER_LOAD: string;
	readonly TENANT_REGISTER_APPROVE: string;
	readonly TENANT_REGISTER_REJECT: string;

	readonly TENANT_CURRENT: string;
	readonly TENANT_CREATE: string;
	readonly TENANT_UPDATE: string;
	readonly TENANT_PROFILE_UPDATE: string;
	readonly TENANT_LOAD: string;
	readonly TENANT_LIST: string;
	readonly TENANT_LIST_ALL: string;

	readonly APIKEY_LIST: string;
	readonly APIKEY_DELETE: string;
	readonly APIKEY_DISABLE: string;
	readonly APIKEY_ENABLED: string;
	readonly APIKEY_REQUEST: string;

	readonly ORGAN_CURRENT: string;
	readonly ORGAN_CREATE: string;
	readonly ORGAN_UPDATE: string;
	readonly ORGAN_LOAD: string;
	readonly ORGAN_LIST: string;
	readonly ORGAN_LIST_FOR_CREATE: string;
	readonly ORGAN_LIST_FOR_EDIT: string;
	readonly ORGAN_UPLOAD_SYNC: string;
	readonly ORGAN_QUERY: string;

	readonly ACCOUNT_CURRENT: string;
	readonly ACCOUNT_CREATE: string;
	readonly ACCOUNT_UPDATE: string;
	readonly ACCOUNT_LIST: string;
	readonly ACCOUNT_LOAD: string;
	readonly ACCOUNT_SECURE_INFO_LOAD: string;
	readonly ACCOUNT_UPLOAD_SYNC: string;
	readonly ACCOUNT_MENU_BY_TENANT_LOAD: string;
	readonly ACCOUNT_MENU_BY_TENANT_UPDATE: string;
	readonly ACCOUNT_CHECK_SALES_AUTH: string;
	readonly ACCOUNT_CHECK_ACCOUNT_EXIST: string;
	readonly ACCOUNT_FIND_HQ_ACCOUNT_CODE: string;

	readonly PROBATION_SERVICE_LIST: string;
	readonly PROBATION_SERVICE_LOAD: string;
	readonly PROBATION_SERVICE_UPDATE: string;
	readonly PROBATION_SERVICE_APPLY: string;

	readonly COURSE_LIST: string;
	readonly COURSE_CREATE: string;
	readonly COURSE_UPDATE: string;

	readonly VENDOR_LIST: string;
	readonly VENDOR_AVAILABLE_LIST: string;
	readonly VENDOR_LIST_CHANNEL: string;
	readonly PRODUCT_LIST: string;
	readonly PRODUCT_LIST_BY_VENDOR: string;
	readonly PRODUCT_LOAD: string;
	readonly PRODUCT_LOAD_BY_CODE: string;
	readonly PRODUCT_CREATE: string;
	readonly PRODUCT_UPDATE: string;

	readonly PRODUCT_TAG_LIST: string;
	readonly PRODUCT_TAG_LIST_CATEGORY: string;
	readonly PRODUCT_TAG_LIST_HIERARCHICAL: string;
	readonly PRODUCT_TAG_LOAD: string;
	readonly PRODUCT_TAG_DELETE: string;
	readonly PRODUCT_TAG_SAVE: string;
	readonly PRODUCT_TAG_JOINT_SAVE: string;

	readonly AGREEMENT_LIST: string;
	readonly AGREEMENT_LIST_BY_TENANT_AND_VENDOR: string;
	readonly AGREEMENT_LOAD: string;
	readonly AGREEMENT_CREATE: string;
	readonly AGREEMENT_UPDATE: string;
	readonly AGREEMENT_CLONE: string;
	readonly AGREE_PRODUCT_CHANNEL_LOAD: string;
	readonly AGREE_PRODUCT_CHANNEL_LIST_ORGAN_LIST_ORGAN_AVAILABLE: string;

	readonly DASHBOARD_LOAD: string;
	readonly DASHBOARD_GENERATE: string;

	readonly SMS_QUERY: string;
	readonly SMS_BLACK_LIST_QUERY: string;
	readonly SMS_SEND_VALIDATE: string;
	readonly REFRESH_PIC_CODE: string;
	readonly VERIFY_PIC_CODE: string;
	readonly ACCOUNT_SYNC_QUERY: string;

	readonly MESSAGE_CENTER_LIST: string;
	readonly MESSAGE_CENTER_CREATE: string;

	readonly POLICY_DESK_LIST: string;
	readonly POLICY_URL_LIST: string;
	readonly POLICY_REPORT_GENERATE: string;
	readonly POLICY_LOAD: string;
	readonly POLICY_FIND_BY_POLICY_NO: string;
	readonly POLICY_FIND_BY_QUOTE_NO: string;
	readonly POLICY_FIND_MINI_BY_QUOTE_NO: string;
	readonly POLICY_FIND_HISTORY_BY_QUOTE_NO: string;
	readonly POLICY_CREATE_V2: string;
	readonly POLICY_CREATE_V1: string;
	readonly POLICY_UPDATE_V2: string;
	readonly POLICY_UPDATE_V1: string;
	readonly POLICY_CALCULATE_V2: string;
	readonly POLICY_CALCULATE_ADVICE_V2: string;
	readonly POLICY_ILLUSTRATE_V2: string;
	readonly POLICY_CALCULATE_V1: string;
	readonly POLICY_APPLY_V2: string;
	readonly POLICY_UPLOAD_V2: string;
	readonly POLICY_APPLY_V1: string;
	readonly POLICY_UNDERWRITE_V2: string;
	readonly POLICY_UNDERWRITE_V1: string;
	readonly POLICY_AUTH_QUERY_V2: string;
	readonly POLICY_AUTH_APPLY_V2: string;
	readonly POLICY_AUTH_CONFIRM_V2: string;
	readonly POLICY_SUBMIT_ORDER_V2: string;
	readonly POLICY_SUBMIT_POLICY_V2: string;
	readonly POLICY_SUBMIT_POLICY_ASYNC_V2: string;
	readonly POLICY_ISSUE_V2: string;
	readonly POLICY_ISSUE_V1: string;
	readonly POLICY_WITHDRAW_V2: string;
	readonly POLICY_REQUOTE_V2: string;
	readonly POLICY_RECEIPT_V2: string;
	readonly ABANDON_SIGNATURE_IDS: string;
	readonly LOAD_CA_SIGNATURES: string;
	readonly CREATE_SIGNATURE: string;
	readonly UPDATE_SIGNATURE: string;
	readonly REGISTER_SIGNATURE: string;
	readonly CHECK_SIGNATURE: string;
	readonly SEND_VALIDATION_CODE: string;
	readonly SEND_COMMON_SMS_CODE: string;
	readonly SEND_VENDOR_COMMON_SMS_CODE: string;
	readonly VALIDATE_VALIDATION_CODE: string;
	readonly VALIDATE_COMMON_SMS_CODE: string;
	readonly VALIDATE_COMPLETE_SIGNATURE: string;
	readonly CONFIRM_SIGNATURE: string;
	readonly CHECK_RECEIPT: string;
	readonly DOC_GENERATE: string;
	readonly SIGN_PDF: string;
	readonly SIGN_PDF_V2: string;
	readonly POLICY_IMAGE_UPLOAD: string;
	readonly POLICY_IMAGE_UPLOAD_V2: string;
	readonly POLICY_VIDEO_UPLOAD: string;
	readonly POLICY_DOCUMENT_UPLOAD: string;
	readonly POLICY_COLLECT_PREMIUM: string;
	readonly POLICY_SYNC: string;
	readonly CUSTOMER_LIST_CONDITION: string;
	readonly CUSTOMER_LOAD: string;
	readonly POLICY_NOTIFY_LOG_LIST: string;
	readonly POLICY_NOTIFY_LOG_LOAD: string;
	readonly POLICY_NOTIFY_PROCESS_LOG_RETRY: string;
	readonly POLICY_NOTIFY_PROCESS_LOG_LOAD: string;
	readonly POLICY_GENERATE_QUOTE_NO: string;
	readonly POLICY_CHECK_INSURED: string;
	readonly QUERY_AGENT_INFO: string;
	readonly AIXIN_WX_SIGN: string;
	readonly POLICY_PROBLEM_LIST: string;
	readonly POLICY_PROBLEM_SOLVE: string,
	readonly POLICY_ADDRESS_VALIDITY_V2: string;
	readonly UPLOAD_UPDATE_HISTORY: string;
	readonly UPDATE_STATUS_AND_HISTORY: string;
	readonly DOCUMENT_LIST: string;
	readonly AUDIT_LOG_LIST: string;
	readonly AUDIT_LOG_DOWNLOAD: string;
	readonly POLICY_ADDRESS_VALIDITY: string;
	readonly POLICY_CHECK_PHONE: string;

	readonly STATISTICS_REPORT_GENERATE: string;

	readonly MOBILE_HOME_PRODUCT_LIST: string;
	readonly PRODUCT_CATEGORY_LIST: string;
	readonly PRODUCT_LIST_BY_KEYWORD: string;
	readonly PRODUCT_LIST_BY_VENDOR_IDS: string;
	readonly PRODUCT_LIST_BY_VENDOR_CODE: string;
	readonly PRODUCT_LIST_BY_1ST_TAGS: string;
	readonly PRODUCT_LIST_BY_1ST_TAG_NAMES: string;
	readonly PRODUCT_LIST_BY_3RD_TAGS: string;
	readonly PRODUCT_LIST_BY_ADVICE: string;


	readonly MY_POLICY_LIST_BY_STATUS: string;
	readonly MY_POLICY_LIST_BY_CRITERIA: string;
	readonly MY_CUSTOMER_LIST: string;
	readonly POLICY_LIST_BY_MY_CUSTOMER: string;
	readonly LOAD_CUSTOMER_BY_KEYS: string;
	readonly FAVORITE_PRODUCT_LIST: string;
	readonly FAVORITE_PRODUCTS_UNLIKE: string;
	readonly FAVORITE_PRODUCT_LIKE: string;
	readonly FAVORITE_PRODUCT_UNLIKE: string;
	readonly FAVORITE_PRODUCT_IS_LIKED: string;

	readonly CHECK_AGREEMENT: string;

	readonly WECHAT_SIGNATURE: string;
	readonly WECHAT_TOC_REGISTER: string;

	readonly GLUTTON_ASK_TOKEN: string;
	readonly STS_ASK_TOKEN: string;

	readonly POLICY_PAYMENT_YIAN: string;
	readonly POLICY_PAYMENT_FOSUN: string;
	readonly POLICY_PAYMENT_FOSUN_DT: string;
	readonly POLICY_PAYMENT_ZHONGAN: string;
	readonly POLICY_PAYMENT_DINGCHENG: string;
	readonly POLICY_DECLARATION_ZHONGAN: string;
	readonly POLICY_PAYMENT_YANGG: string;
	readonly POLICY_PAYMENT_QUERY: string;
	readonly POLICY_PAYMENT_APPLY: string;
	readonly POLICY_PAYMENT_CONFIRM: string;
	readonly POLICY_PAYMENT_KUNLUN: string;
	readonly POLICY_PAYMENT_STARRCHINA: string;


	readonly STATEMENT_AGREEMENT_LIST: string;
	readonly STATEMENT_AGREEMENT_LIST_BY_TENANT_AND_VENDOR: string;
	readonly STATEMENT_AGREEMENT_LOAD: string;
	readonly STATEMENT_AGREEMENT_DELETE: string;
	readonly STATEMENT_AGREEMENT_CREATE_OR_UPDATE: string;
	readonly STATEMENT_AGREEMENT_BY_PRODUCT_ID: string;
	readonly STATEMENT_COMMISSION_BY_PRODUCT_ID: string;
	readonly STATEMENT_COMMISSION_CREATE_OR_UPDATE: string;
	readonly STATEMENT_BATCH_EXECUTE_BILL: string;
	readonly STATEMENT_RESULT_BY_BATCH_ID: string;
	readonly STATEMENT_RESULT_LIST: string;
	readonly STATEMENT_BATCH_QUERY_ORGAN: string;


	readonly OCR_IDCARD_FRONT: string;
	readonly OCR_BANKCARD_FRONT: string;
	readonly OCR_HANDWRITING: string;

	readonly MALL_PRODUCT_LIST_HOME: string;
	readonly MALL_PRODUCT_LIST_BY_KEYWORD: string;
	readonly MALL_PRODUCT_LIST_BY_PROMOTION: string;
	readonly MALL_PRODUCT_LIST_BY_TAG: string;
	readonly MALL_PRODUCT_LIST_BY_TAGS: string;
	readonly MALL_PRODUCT_CHECK_AGREEMENT: string;
	readonly MY_MALL_POLICY_LIST_BY_STATUS: string;
	readonly MY_LVJIAN_POLICY_LIST_BY_STATUS: string;
	readonly MY_LVJIAN_POLICY_LIST_BY_MASK: string;
	readonly PRODUCT_LOAD_BY_CODE_NO_LOGIN: string;
	readonly MALL_CUSTOMER_SIGNIN: string;
	readonly MALL_CUSTOMER_SIGNIN_SMS_CODE: string;
	readonly MY_MALL_CUSTOMER_POLICY_LIST_BY_STATUS: string;
	readonly MY_MALL_2C_POLICY_LIST_BY_STATUS: string;
	readonly MY_MALL_CHANNEL_POLICY_LIST_BY_STATUS: string;

	readonly POLICE_VERIFY_WITHOUT_FACE: string;

	readonly MALL_POLICY_SHARE_SMS_CODE: string;
	readonly MALL_POLICY_SHARE_SMS_CODE_CHECK: string;

	readonly WEIXIN_GET_OPEN_ID: string;
	readonly LOAD_ACCOUNT_BY_OPEN_ID: string;
	readonly LOAD_WECHATACCOUNT_BY_ACCOUNTNAME: string;
	readonly AUTH_SIGNIN_FOR_WECHAT: string;
	readonly AUTH_SIGNIN_FOR_WECHAT_BY_OPENID: string;
	readonly TOC_UPDATE_ACCOUNT_INFO: string;
	readonly TOC_UNBOUND_WX_ACCOUNT: string;

	readonly IDENTITY_CERTIFICATION_LIST: string;
	readonly IDENTITY_CERTIFICATION_EXCEL_EXPORT: string;
	readonly IDENTITY_NAME_FACE_VERIFY: string;
	readonly BASEINFO_AREA: string;
	readonly BASEINFO_JOB: string;
	readonly MALL_APPOINT_CREATE_PROSPECT: string;
	readonly AUDIT_QUERY_LIST: string;
	readonly AUDIT_CREATE: string;
	readonly QUERY_AUDIT_RESULT: string;
	readonly AUDIT_APPLY: string;

	readonly IMAGE_AUDIT_INFO_BY_ID: string;
	readonly IMAGE_AUDIT_EXAMINE: string;
	readonly AUDIT_DELETE: string;
	readonly GUOHUA_DOWNLOAD_EPOLICY: string;
	readonly GUOHUA_MANUAL_UNDERWRITING: string;

	readonly QUERY_COLLECT_STATUS: string;

	readonly CHANGE_BANK_INFO: string;

	readonly FACE_RECOGNIZE_RESETALL: string;
	readonly FACE_RECOGNIZE_RESULT: string;
	readonly ID_LIVING_DETECT: string;
	
	//海报相关
	readonly ACCOUNT_POLICY_STATES_NUMBER: string;
	//企业预约相关
	readonly ENTERPRISE_ADD: string;

	// 履践
	readonly LVJIAN_SEND_EMAIL_VALICODE: string;
	readonly LVJIAN_SIGN_IN_EMIAL: string;
	readonly LVJIAN_ORG_CHECK: string;
	readonly LVJIAN_REPEAT_EMAIL_CHECK: string;
	readonly LVJIAN_INSURED_INFORMATION: string;
	readonly POLICY_FIND_BY_QUOTE_NO_AND_ACCOUNT_ID: string;

	//claim相关
	readonly CLAIM_VB_POLICY_BY_ID_AND_INSPARGPID: string;

	readonly LVJIAN_CORREACT_QUERY: string;
	readonly LVJIAN_LOAN_APPLICATION_MESSAGE: string;
	readonly LVJIAN_LOAN_APPLICATION_SUBMIT: string;
	readonly LVJIAN_FING_SURENDER_APPLICATION: string;
	readonly LVJIAN_Surrender_APPLICATION_ISClLAIM: string;
	readonly LVJIAN_TURN_NEW_INSURANCE_LOAD: string;
	readonly LVJIAN_TURN_NEW_INSURANCE_LISTID: string;
	readonly LVJIAN_TURN_NEW_INSURANCE_GET_PLANS_BYLIST: string;
	readonly LVJIAN_TURN_NEW_INSURANCE_SUBMIT: string;
	readonly LVJIAN_CLAIM_QUERY: string;
	readonly LVJIAN_CLAIM_CANCEL: string;
	readonly SERVER_LESS_QUERY_ES_: string;

	readonly CREATE_SEARCH_HISTORY: string;
	readonly QUERY_SEARCH_HISTORY: string;
	readonly DELETE_SEARCH_HISTORY: string;

	readonly SEND_EMAIL: string;

	readonly FAMILY_MEMBER_LIST_ACCOUNT_ID: string;
	readonly FAMILY_MEMBER_UPDATE: string;
	readonly FAMILY_MEMBER_DELETE: string;
	readonly FAMILY_MEMBER_INFO_MEMBER_ID: string;

	readonly GET_RENEW_POLICYIDS: string;
	readonly GET_VENDOR_URL: string;


	readonly GET_OPENID_BY_CODE: string;
	readonly WEIXIN_SIGNIN: string;
	readonly CHECK_STATUS_BY_OPENID: string;
	readonly OPENID_BINDING_BY_PASSWORD: string;
	readonly OPENID_BINDING_BY_SMS: string;
	readonly UNBIND_BY_OPENID: string;
	readonly GET_MOBILE_BY_OPENID: string;
	readonly UNBIND_BY_MOBILE: string;

};
/**
 * 所有API路由声明
 */
export default {

	//MEJ
	MEJ_SIGNIN: '/rest/v2/auth/signin',           //密码登录
	HMB_SIGNIN: '/rest/v2/auth/signinForHmb',           //密码登录
	MEJ_SIGNUP: '/rest/v2/auth/signup',            //用户注册
	MEJ_VALIDATE: '/rest/v1/sms/sendValidateCode', //发送验证码
	MEJ_SMS: '/rest/v2/auth/smsSignin',            //验证码登录
	HMB_SMS: '/rest/v2/auth/smsSigninForHmb',            //验证码登录
	MEJ_FORGET: '/rest/v2/auth/changePassword',    //重置密码
	MEJ_ORGAN: '/rest/v1/organ/query',             //机构查询 企业link
	PRODUCT_DISCLOSURE: '/rest/v1/product/disclosure/list/criteria?pageSize=9999999', // 互联网产品信息披露加载
	COOPERATIVE_INSURER_DISCLOSURE: '/rest/v1/product/vendor/disclosure/list/criteria', // 合作保司信息披露加载
	TENANT_CONFIGURATION_LIST: '/rest/v1/tenant/configuration/list',             //营销配置查询 1:密码策略 2:销售区域 3:营销配置 8:热门词汇
	PRODUCT_LIST_BYCHANEL: '/rest/v1/product/listByChannel',                           // 根据查询条件列出所有产品
	QUERY_PRODUCT_TAG: '/rest/v1/product/tag/list3rdByProductJoint', // 查询已关联产品的三级标签
	AUTH_CHANGEMOLIE: '/rest/v2/auth/changeMobile',                     //验证码校验以及手机号修改
	AUTH_CHANGEMOLIE_HMB: '/rest/v2/auth/changeMobileForHmb',                     //验证码校验以及手机号修改
	DISABLE_ACCOUNTNAME: '/rest/v2/auth/disable',                          //注销账户
	UPDATE_ACCOUNT: '/rest/v1/account/update',                             //个人中心-编辑个人信息
	HMB_UPDATE_ACCOUNT: '/rest/v1/account/hmbUpdate',                             //个人中心-编辑个人信息
	ACCOUNT_MASK: '/rest/v1/account/mask', // 获取个人信息掩码
	CHECK_VALIDATIONCODE: '/rest/v1/sms/checkValidationCode',   //个人信息编辑 校验验证码
	QUERY_PERSONAL: '/rest/v1/account/current',              //查询个人信息
	ACCOUNT_AVATAR: '/rest/v1/account/avatar',
	ACCOUNT_AVATAR_HMB: '/rest/v1/account/avatarForHmb',
	POSTER_TOKEN: '/rest/v2/auth/getQrCodeToken',
	POSTER_LIST_AGENT: '/rest/v1/poster/queryByCurrentAgent',
	POSTER_LOAD_BY_ID: '/rest/v1/poster/loadById',
	ACCOUNT_FOR_AGENT_LOAD: '/rest/v1/account/loadByIdForAgent/',
	POSTER_ISUSEQRCODE_BY_ID: '/rest/v1/poster/changeIsUseQRCode/',
	ACCOUNT_BIND_ORG: '/rest/v1/account/binding',
	LOAD_FIRST_CHANNEL: '/rest/v1/organ/load/FirstChannel/',


	AUTH_RETRIEVE: '/rest/v2/auth/retrieve',
	AUTH_SIGNUP: '/rest/v1/auth/signup',
	AUTH_SIGNUP_V2: '/rest/v2/auth/signup',            //用户注册
	AUTH_TRUST_SIGNIN: '/rest/v1/auth/trustSignin',
	AUTH_SIGNIN: '/rest/v1/auth/signin',
	AUTH_SIGNIN_V2: '/rest/v2/auth/signin',
	AUTH_FORGET_PWD: '/rest/v1/auth/forgetp/',
	AUTH_REGISTER: '/rest/v1/auth/register',
	AUTH_REGISTER2C: '/rest/v1/auth/register2C',
	AUTH_SIGNOUT: '/rest/v1/auth/signout',//登出
	AUTH_CHANGE_PWD: '/rest/v2/auth/changePassword',    //重置密码
	AUTH_SMS_SIGNIN: '/rest/v2/auth/smsSignin',            //验证码登录
	AUTH_CHANGE_MOLIE: '/rest/v1/auth/changeMobile',
	AUTH_DISABLE: '/rest/v2/auth/disable',
	AUTH_VALIDATE_UNREADAGREEMENT: '/rest/v1/auth/validateUnReadAgreement',//阅读协议验证
	CHANGE_PWD: '/rest/v1/auth/changep',
	AVATAR_UPLOAD: '/rest/v1/account/avatar',
	DIRECT_ASK: '/rest/v1/tenant/direct/ask/',

	
	
	
	
	
	
	
	

	TENANT_REGISTER_APPLY: '/rest/v1/tenant/register/create',
	TENANT_REGISTER_LIST: '/rest/v1/tenant/register/list/criteria',
	TENANT_REGISTER_LOAD: '/rest/v1/tenant/register/load/',
	TENANT_REGISTER_APPROVE: '/rest/v1/tenant/register/approve',
	TENANT_REGISTER_REJECT: '/rest/v1/tenant/register/reject',

	TENANT_CURRENT: '/rest/v1/tenant/current',
	TENANT_CREATE: '/rest/v1/tenant/create',
	TENANT_UPDATE: '/rest/v1/tenant/update',
	TENANT_PROFILE_UPDATE: '/rest/v1/account/menus/tenant/profile/update',
	TENANT_LOAD: '/rest/v1/tenant/load/',
	TENANT_LIST: '/rest/v1/tenant/list/criteria',
	TENANT_LIST_ALL: '/rest/v1/tenant/list/all',

	APIKEY_LIST: '/rest/v1/account/api-key/list',
	APIKEY_DELETE: '/rest/v1/account/api-key/delete',
	APIKEY_DISABLE: '/rest/v1/account/api-key/disable',
	APIKEY_ENABLED: '/rest/v1/account/api-key/enable',
	APIKEY_REQUEST: '/rest/v1/account/api-key/sign',

	ORGAN_CURRENT: '/rest/v1/organ/current',
	ORGAN_CREATE: '/rest/v1/organ/create',
	ORGAN_UPDATE: '/rest/v1/organ/update',
	ORGAN_LOAD: '/rest/v1/organ/load/',
	ORGAN_LIST: '/rest/v1/organ/list/criteria',
	ORGAN_LIST_FOR_CREATE: '/rest/v1/organ/list/create/',
	ORGAN_LIST_FOR_EDIT: '/rest/v1/organ/list/edit/',
	ORGAN_UPLOAD_SYNC: '/rest/v1/organ/uploadSync',
	ORGAN_QUERY: '/rest/v1/organ/query',             //机构查询 企业link

	ACCOUNT_CURRENT: '/rest/v1/account/current',
	ACCOUNT_CREATE: '/rest/v1/account/create',
	ACCOUNT_UPDATE: '/rest/v1/account/update',
	ACCOUNT_LIST: '/rest/v1/account/list/criteria',
	ACCOUNT_LOAD: '/rest/v1/account/load/',
	ACCOUNT_SECURE_INFO_LOAD: '/rest/v1/account/secure/',
	ACCOUNT_UPLOAD_SYNC: '/rest/v1/account/uploadSync',
	ACCOUNT_MENU_BY_TENANT_LOAD: '/rest/v1/account/menus/tenant/load/',
	ACCOUNT_MENU_BY_TENANT_UPDATE: '/rest/v1/account/menus/tenant/update',
	ACCOUNT_CHECK_SALES_AUTH: '/rest/v1/account/checkAgentSaleAuthority/',
	ACCOUNT_CHECK_ACCOUNT_EXIST: '/rest/v1/account/checkAccountExist/',
	ACCOUNT_FIND_HQ_ACCOUNT_CODE: '/rest/v1/account/findHQAccountCode/',

	PROBATION_SERVICE_LIST: '/rest/v1/servicing/probation/list',
	PROBATION_SERVICE_LOAD: '/rest/v1/servicing/probation/load/',
	PROBATION_SERVICE_UPDATE: '/rest/v1/servicing/probation/update',
	PROBATION_SERVICE_APPLY: '/rest/v1/servicing/probation/apply',

	COURSE_LIST: '/rest/v1/course/list/criteria',
	COURSE_CREATE: '/rest/v1/course/create',
	COURSE_UPDATE: '/rest/v1/course/update',

	VENDOR_LIST: '/rest/v1/product/vendor/list',
	VENDOR_AVAILABLE_LIST: '/rest/v1/product/vendor/list/available',
	VENDOR_LIST_CHANNEL: '/rest/v1/dashboard/list/endSalesChannel',
	PRODUCT_LIST: '/rest/v1/product/list/criteria',
	PRODUCT_LIST_BY_VENDOR: '/rest/v1/product/list/vendors/assignTenant',
	PRODUCT_LOAD: '/rest/v1/product/load/',
	PRODUCT_LOAD_BY_CODE: '/rest/v1/product/loadProduct/',
	PRODUCT_CREATE: '/rest/v1/product/create',
	PRODUCT_UPDATE: '/rest/v1/product/update',

	PRODUCT_TAG_LIST: '/rest/v1/product/tag/list/criteria',
	PRODUCT_TAG_LIST_CATEGORY: '/rest/v1/product/tag/list/system/category',
	PRODUCT_TAG_LIST_HIERARCHICAL: '/rest/v1/product/tag/list/system/hierarchical',
	PRODUCT_TAG_LOAD: '/rest/v1/product/tag/load/',
	PRODUCT_TAG_DELETE: '/rest/v1/product/tag/delete/',
	PRODUCT_TAG_SAVE: '/rest/v1/product/tag/save',
	PRODUCT_TAG_JOINT_SAVE: '/rest/v1/product/tag/save/joints/',

	AGREEMENT_LIST: '/rest/v1/agree/list/criteria',
	AGREEMENT_LOAD: '/rest/v1/agree/load/',
	AGREEMENT_CREATE: '/rest/v1/agree/create',
	AGREEMENT_UPDATE: '/rest/v1/agree/update',
	AGREEMENT_CLONE: '/rest/v1/agree/clone/',
	AGREE_PRODUCT_CHANNEL_LOAD: '/rest/v1/agree/product/channel/list/organ',
	AGREE_PRODUCT_CHANNEL_LIST_ORGAN_LIST_ORGAN_AVAILABLE: '/rest/v1/agree/product/channel/list/organ/available',

	DASHBOARD_LOAD: '/rest/v1/dashboard/loadShowData',
	DASHBOARD_GENERATE: '/rest/v1/dashboard/generate',

	SMS_QUERY: '/rest/v1/sms/query',
	SMS_BLACK_LIST_QUERY: '/rest/v1/sms/blackList/query',
	SMS_SEND_VALIDATE: '/rest/v1/sms/sendValidateCode', //发送验证码
	REFRESH_PIC_CODE: '/rest/v1/sms/refresh/picCode', //刷新图片验证码接口
	VERIFY_PIC_CODE: '/rest/v1/sms/verify/picCode', //校验图片验证码接口
	ACCOUNT_SYNC_QUERY: '/rest/v1/account/sync/query',

	MESSAGE_CENTER_LIST: '/rest/v1/message/list/criteria',
	MESSAGE_CENTER_CREATE: '/rest/v1/message/create',

	POLICY_DESK_LIST: '/rest/v1/policy/list/desk/criteria',
	POLICY_URL_LIST: '/rest/v1/policy/url/list',

	POLICY_REPORT_GENERATE: '/rest/v1/policy/report/generate',
	POLICY_LOAD: '/rest/v2/policy/find/id/',
	POLICY_FIND_BY_POLICY_NO: '/rest/v2/policy/findBypolicyNo/',
	POLICY_FIND_BY_QUOTE_NO: '/rest/v2/policy/find/quoteNo/',
	POLICY_FIND_HISTORY_BY_QUOTE_NO: '/rest/v2/policy/find/historyPolicy/quoteNo/',
	POLICY_FIND_MINI_BY_QUOTE_NO: '/rest/v2/policy/find/miniPolicy/quoteNo/',
	POLICY_CREATE_V2: '/rest/v2/policy/create',
	POLICY_CREATE_V1: '/rest/v1/policy/create',
	POLICY_UPDATE_V2: '/rest/v2/policy/update',
	POLICY_UPDATE_V1: '/rest/v1/policy/update',
	POLICY_SYNC: '/rest/v1/policy/async',
	POLICY_CALCULATE_V2: '/rest/v2/policy/calculate',
	POLICY_CALCULATE_ADVICE_V2: '/rest/v2/policy/calculateForAdvise',
	POLICY_ILLUSTRATE_V2: '/rest/v2/policy/illustration',
	POLICY_CALCULATE_V1: '/rest/v1/policy/calculate',
	POLICY_APPLY_V2: '/rest/v2/policy/apply',
	POLICY_UPLOAD_V2: '/rest/v2/policy/uploadDocument',
	POLICY_APPLY_V1: '/rest/v1/policy/apply',
	POLICY_UNDERWRITE_V2: '/rest/v2/policy/underwrite',
	POLICY_UNDERWRITE_V1: '/rest/v1/policy/underwrite',
	POLICY_AUTH_QUERY_V2: '/rest/v2/policy/payment/auth/query',
	POLICY_AUTH_APPLY_V2: '/rest/v2/policy/payment/auth/apply',
	POLICY_AUTH_CONFIRM_V2: '/rest/v2/policy/payment/auth/confirm',
	POLICY_ISSUE_V2: '/rest/v2/policy/issue',
	POLICY_ISSUE_V1: '/rest/v1/policy/issue',
	POLICY_WITHDRAW_V2: '/rest/v2/policy/withdraw',
	POLICY_REQUOTE_V2: '/rest/v2/policy/requote',
	POLICY_RECEIPT_V2: '/rest/v2/policy/receipt',
	POLICY_SUBMIT_ORDER_V2: '/rest/v2/policy/submitOrder',
	POLICY_SUBMIT_POLICY_V2: '/rest/v2/policy/submitPolicy',
	POLICY_SUBMIT_POLICY_ASYNC_V2: '/rest/v2/policy/asyncSubmitPolicy',
	ABANDON_SIGNATURE_IDS: '/rest/v1/policy/signature/setTimeout',
	LOAD_CA_SIGNATURES: '/rest/v2/ca/loadBySignedDocIds',
	CREATE_SIGNATURE: '/rest/v1/policy/signature/create',
	UPDATE_SIGNATURE: '/rest/v1/policy/signature/update',
	REGISTER_SIGNATURE: '/rest/v1/policy/signature/register',
	CHECK_SIGNATURE: '/rest/v1/policy/signature/checkEffective/',
	SEND_VALIDATION_CODE: '/rest/v1/policy/signature/sendCode',
	SEND_COMMON_SMS_CODE: '/rest/v1/sms/sendValidateCode/',
	SEND_VENDOR_COMMON_SMS_CODE: '/rest/v1/sms/sendValidateCodeForVendor/',
	VALIDATE_VALIDATION_CODE: '/rest/v1/policy/signature/validate',
	VALIDATE_COMMON_SMS_CODE: '/rest/v1/sms/codeValidation/',
	VALIDATE_COMPLETE_SIGNATURE: '/rest/v1/policy/signature/validateComplete',
	CONFIRM_SIGNATURE: '/rest/v1/policy/signature/confirm',
	CHECK_RECEIPT: '/rest/v2/policy/checkReceipt',
	DOC_GENERATE: '/rest/v1/policy/doc/generate',
	SIGN_PDF: '/rest/v1/ca/sign/signpdf',
	SIGN_PDF_V2: '/rest/v2/ca/sign',
	POLICY_IMAGE_UPLOAD: '/rest/v1/policy/image/upload',
	POLICY_IMAGE_UPLOAD_V2: '/rest/v2/policy/image/upload',
	POLICY_VIDEO_UPLOAD: '/rest/v2/policy/video/upload',
	POLICY_DOCUMENT_UPLOAD: '/rest/v1/policy/doc/upload',
	POLICY_COLLECT_PREMIUM: '/rest/v2/policy/collectPrem',
	CUSTOMER_LIST_CONDITION: '/rest/v1/client/list/condition',
	CUSTOMER_LOAD: '/rest/v1/client/detail',
	POLICY_NOTIFY_LOG_LIST: '/rest/v1/policy/log/list/criteria',
	POLICY_NOTIFY_LOG_LOAD: '/rest/v1/policy/log/find/',
	POLICY_NOTIFY_PROCESS_LOG_RETRY: '/rest/v1/policy/log/process/retry/',
	POLICY_NOTIFY_PROCESS_LOG_LOAD: '/rest/v1/policy/log/find/policy/',
	POLICY_GENERATE_QUOTE_NO: '/rest/v2/policy/getInsurerProposalCode',
	POLICY_CHECK_INSURED: '/rest/v2/policy/checkInsured',
	QUERY_AGENT_INFO: '/rest/v2/policy/queryAgentInfo',
	AIXIN_WX_SIGN: '/rest/v1/sign/aixin/getInsuranceSignUrl',
	POLICY_PROBLEM_LIST: '/rest/v1/policy/problem/list',
	POLICY_PROBLEM_SOLVE: '/rest/v1/policy/problem/solve',
	POLICY_ADDRESS_VALIDITY_V2: '/rest/v2/policy/addressValidity',
	UPLOAD_UPDATE_HISTORY: '/rest/v2/policy/uploadAndUpdateHistory',
	UPDATE_STATUS_AND_HISTORY: '/rest/v2/policy/updateStatusAndHistory',
	DOCUMENT_LIST: '/rest/v1/document/listByQuotationNo/',
	AUDIT_LOG_LIST: '/rest/v2/audit/log/list/criteria',
	AUDIT_LOG_DOWNLOAD: '/rest/v2/audit/log/download',
	POLICY_ADDRESS_VALIDITY: '/rest/v2/policy/addressValidity',
	POLICY_CHECK_PHONE: '/rest/v2/policy/checkPhone',

	STATISTICS_REPORT_GENERATE: '/rest/v1/statistics/generate',

	MOBILE_HOME_PRODUCT_LIST: '/rest/v1/product/list/homepage',
	PRODUCT_CATEGORY_LIST: '/rest/v1/product/category/list',
	PRODUCT_LIST_BY_KEYWORD: '/rest/v1/product/list/keyword/',
	PRODUCT_LIST_BY_VENDOR_IDS: '/rest/v1/product/list/vendors',
	PRODUCT_LIST_BY_VENDOR_CODE: '/rest/v1/product/list/vendorCode',
	PRODUCT_LIST_BY_1ST_TAGS: '/rest/v1/product/list/tags',
	PRODUCT_LIST_BY_1ST_TAG_NAMES: '/rest/v1/product/list/tagNames',
	PRODUCT_LIST_BY_3RD_TAGS: '/rest/v1/product/list/tags/3rd',
	PRODUCT_LIST_BY_ADVICE: '/rest/v1/product/list/advice',

	MY_POLICY_LIST_BY_STATUS: '/rest/v1/policy/list/my/status',
	MY_POLICY_LIST_BY_CRITERIA: '/rest/v1/policy/list/my/criteria',
	MY_CUSTOMER_LIST: '/rest/v1/client/list',
	POLICY_LIST_BY_MY_CUSTOMER: '/rest/v1/client/policy',
	LOAD_CUSTOMER_BY_KEYS: '/rest/v1/client/detailByKeys',
	FAVORITE_PRODUCT_LIST: '/rest/v1/product/favorite/list',
	FAVORITE_PRODUCTS_UNLIKE: '/rest/v1/product/favorite/unlike',
	FAVORITE_PRODUCT_LIKE: '/rest/v1/product/favorite/like/',
	FAVORITE_PRODUCT_UNLIKE: '/rest/v1/product/favorite/unlike/',
	FAVORITE_PRODUCT_IS_LIKED: '/rest/v1/product/favorite/',

	CHECK_AGREEMENT: '/rest/v1/product/checkAgreementProduct/',

	WECHAT_SIGNATURE: `/rest/v1/wechat/create/signature${asHostBy().wechatPrefixName}`,
	WECHAT_TOC_REGISTER: '/rest/v1/sms/sendTocValidateCode',

	GLUTTON_ASK_TOKEN: '/rest/v1/glutton/ask/token',
	STS_ASK_TOKEN: '/rest/v1/sts/ask/token',

	POLICY_PAYMENT_YIAN: '/rest/v1/payment/yian/pay',
	POLICY_PAYMENT_FOSUN: '/rest/v1/payment/fosun/payUrl',
	POLICY_PAYMENT_FOSUN_DT: '/rest/v1/payment/fosun/dtPayUrl',
	POLICY_PAYMENT_ZHONGAN: '/rest/v1/zhongan/getPaymentUrl',
	POLICY_PAYMENT_DINGCHENG: '/rest/v1/dingcheng/getPaymentUrl',
	POLICY_DECLARATION_ZHONGAN: '/rest/v1/zhongan/getInsuredIntelligentUnderwritingUrl',
	POLICY_PAYMENT_YANGG: '/rest/v1/payment/yangg/payUrl',
	POLICY_PAYMENT_KUNLUN: '/rest/v1/payment/kunlun/payUrl',
	POLICY_PAYMENT_STARRCHINA: '/rest/v1/payment/starrchina/payUrl',

	POLICY_PAYMENT_QUERY: '/rest/v2/policy/payment/auth/query',
	POLICY_PAYMENT_APPLY: '/rest/v2/policy/payment/auth/apply',
	POLICY_PAYMENT_CONFIRM: '/rest/v2/policy/payment/auth/confirm',

	STATEMENT_AGREEMENT_LIST: '/rest/v1/statement/agreement/query',
	STATEMENT_AGREEMENT_LIST_BY_TENANT_AND_VENDOR: '/rest/v1/statement/agreement/listByCriteria',
	STATEMENT_AGREEMENT_LOAD: '/rest/v1/statement/agreement/load/',
	STATEMENT_AGREEMENT_DELETE: '/rest/v1/statement/agreement/delete/',
	STATEMENT_AGREEMENT_CREATE_OR_UPDATE: '/rest/v1/statement/agreement/createOrSave',
	STATEMENT_AGREEMENT_BY_PRODUCT_ID: '/rest/v1/statement/agreement/querySAlistByConditions',
	STATEMENT_COMMISSION_BY_PRODUCT_ID: '/rest/v1/statement/commission/loadCommissionByConditions',
	STATEMENT_COMMISSION_CREATE_OR_UPDATE: '/rest/v1/statement/commission/createOrSave',
	STATEMENT_BATCH_EXECUTE_BILL: '/rest/v1/statement/batch/executeBill',
	STATEMENT_RESULT_BY_BATCH_ID: '/rest/v1/statement/batch/queryResultByBatchId/',
	STATEMENT_RESULT_LIST: '/rest/v1/statement/batch/queryHistoryResult',
	STATEMENT_BATCH_QUERY_ORGAN: '/rest/v1/statement/batch/queryOrganization',

	OCR_IDCARD_FRONT: '/rest/v1/ocr/idcard',
	OCR_BANKCARD_FRONT: '/rest/v1/ocr/bankcard',
	OCR_HANDWRITING: '/rest/v1/ocr/handwriting',

	MALL_PRODUCT_LIST_HOME: '/rest/v1/mall/product/list/home',
	MALL_PRODUCT_LIST_BY_KEYWORD: '/rest/v1/mall/product/list/keyword',
	MALL_PRODUCT_LIST_BY_PROMOTION: '/rest/v1/mall/product/list/promotion',
	MALL_PRODUCT_LIST_BY_TAGS: '/rest/v1/mall/product/list/tags',
	MALL_PRODUCT_LIST_BY_TAG: '/rest/v1/mall/product/list/tag',
	MALL_PRODUCT_CHECK_AGREEMENT: '/rest/v1/mall/product/checkAgreementProductById',
	MY_MALL_POLICY_LIST_BY_STATUS: '/rest/v1/policy/list/my/mall/status',
	PRODUCT_LOAD_BY_CODE_NO_LOGIN: '/rest/v1/product/loadProductNoLogin/',
	MALL_CUSTOMER_SIGNIN: '/rest/v1/auth/signinForCus',
	MALL_CUSTOMER_SIGNIN_SMS_CODE: '/rest/v1/sms/sendCodeForCusSignIn',
	MY_MALL_CUSTOMER_POLICY_LIST_BY_STATUS: '/rest/v1/policy/list/my/mall/statusForCus',
	MY_MALL_2C_POLICY_LIST_BY_STATUS: '/rest/v1/policy/list/my/mall/statusFor2c',
	MY_MALL_CHANNEL_POLICY_LIST_BY_STATUS: '/rest/v1/policy/list/my/mall/statusForChannel',

	POLICE_VERIFY_WITHOUT_FACE: '/rest/detect/idCardDetect', // 二要素 身份认证(身份证+姓名)

	MALL_POLICY_SHARE_SMS_CODE: '/rest/v1/sms/sendCodeForCusShare/',
	MALL_POLICY_SHARE_SMS_CODE_CHECK: '/rest/v1/sms/checkValidateCodeForMall/',

	IDENTITY_CERTIFICATION_LIST: '/rest/tenant/identity/certification/record/list',
	IDENTITY_CERTIFICATION_EXCEL_EXPORT: '/rest/tenant/identity/certification/record/export',
	IDENTITY_NAME_FACE_VERIFY: '/rest/detect/faceDetect',


	WEIXIN_GET_OPEN_ID: `/rest/v1/weixin/getWxUser${asHostBy().wechatPrefixName}`,
	LOAD_ACCOUNT_BY_OPEN_ID: '/rest/v1/account/getAccountByOpenId/',
	LOAD_WECHATACCOUNT_BY_ACCOUNTNAME: `/rest/v1/account/getAccountByAccoutName${asHostBy().wechatPrefixName}`,
	AUTH_SIGNIN_FOR_WECHAT: '/rest/v2/auth/signInForWechat',
	AUTH_SIGNIN_FOR_WECHAT_BY_OPENID: '/rest/v2/auth/signInForWechatByOpenId',
	TOC_UPDATE_ACCOUNT_INFO: '/rest/v1/auth/updateAccountInfo',
	TOC_UNBOUND_WX_ACCOUNT: '/rest/v1/weixin/unBoundWXAccount',

	BASEINFO_AREA: '/rest/v1/cpcs/baseinfo/list/area/{vendorCode}/{sourceType}/{version}',
	BASEINFO_JOB: '/rest/v1/cpcs/baseinfo/list/job/{vendorCode}/{sourceType}/{version}',
	MALL_APPOINT_CREATE_PROSPECT: '/rest/v1/client/createProspect',

	IMAGE_AUDIT_INFO_BY_ID: '/rest/v1/image/audit/info/',
	IMAGE_AUDIT_EXAMINE: '/rest/v1/image/audit/examine',
	AUDIT_QUERY_LIST: '/rest/v1/image/audit/list/criteria',
	AUDIT_CREATE: '/rest/v1/image/audit/create',
	QUERY_AUDIT_RESULT: '/rest/v1/image/audit/getImageAuditStatusByNo/',
	AUDIT_APPLY: '/rest/v1/image/audit/apply/',

	AUDIT_DELETE: '/rest/v1/image/audit/deleteImageAuditByNo/',
	GUOHUA_DOWNLOAD_EPOLICY: '/rest/v1/guoh/downloadEPolicy',
	GUOHUA_MANUAL_UNDERWRITING: '/rest/v1/guoh/manualUnderwriting',
	QUERY_COLLECT_STATUS: '/rest/v2/policy/queryCollectStatus/',

	CHANGE_BANK_INFO: '/rest/v2/policy/changeBankInfo',
	// 人脸识别相关接口
	FACE_RECOGNIZE_RESULT: '/rest/v2/policy/identity/certification/queryPassedList',
	FACE_RECOGNIZE_RESETALL: '/rest/v2/policy/identity/certification/resetByType',
	ID_LIVING_DETECT: '/rest/detect/livingDetect', // 活体检测
	

	//海报
	ACCOUNT_POLICY_STATES_NUMBER: '/rest/v1/policy/countPerson/{dateType}/{accountId}',

	// 履践
	LVJIAN_SEND_EMAIL_VALICODE: '/rest/v2/policy/vb/send/email',
	LVJIAN_SIGN_IN_EMIAL: '/rest/v2/policy/vb/signIn/email',
	LVJIAN_ORG_CHECK: '/rest/v1/organ/checkOrganizationStatus',
	MY_LVJIAN_POLICY_LIST_BY_STATUS: '/rest/v1/policy/list/my/lvjian/status',
	MY_LVJIAN_POLICY_LIST_BY_MASK: '/rest/v1/account/mask',
	LVJIAN_REPEAT_EMAIL_CHECK: '/rest/v1/account/checkRepeatEmaill',
	POLICY_FIND_BY_QUOTE_NO_AND_ACCOUNT_ID: '/rest/v2/policy/find/quoteNo',
	LVJIAN_INSURED_INFORMATION: '/rest/v2/policy/group/insuredsInformation',
	LVJIAN_Surrender_APPLICATION_ISClLAIM: '/rest/v1/claim/findInProcessClaimByPolicyNo',//判断是否存在理赔
	LVJIAN_LOAN_APPLICATION_MESSAGE: '/rest/v2/policy/find/miniPolicy/quoteNo/:quoteNo',// 退保申请信息录入加载
	LVJIAN_LOAN_APPLICATION_SUBMIT: '/rest/v1/staffQuit/surenderApplicationSave',// 退保申请提交
	LVJIAN_FING_SURENDER_APPLICATION: '/rest/v1/staffQuit/findSurenderApplication',// 查找是否存在退保/工作变更申请
	LVJIAN_CORREACT_QUERY: '/rest/v1/endorsement/findEndoByFullNameAndIdTypeAndIdNo',// 履践-批改查询
	LVJIAN_TURN_NEW_INSURANCE_LOAD: '/rest/v1/endorsement/validateNewProposal',//转新保信息录入带出数据
	LVJIAN_TURN_NEW_INSURANCE_LISTID: '/rest/v1/agree/product/channel/listAgreementProductChannelByChannelAndProduct',//转新保获取listId投保计划下拉数据
	LVJIAN_TURN_NEW_INSURANCE_GET_PLANS_BYLIST: '/rest/v1/agree/product/channel/getPlansByListId',//转新保获取投保计划下拉数据
	LVJIAN_TURN_NEW_INSURANCE_SUBMIT: '/rest/v1/endorsement/createEndorsementForNewProposal',//转新保提交
	LVJIAN_CLAIM_QUERY: '/rest/v1/claim/findSelfClaimList',//理赔查询
	LVJIAN_CLAIM_CANCEL: '/rest/v1/claim/cancel',//撤件
	//企业预约
	ENTERPRISE_ADD: '/rest/v1/enterprise/add',

	//claim相关
	CLAIM_VB_POLICY_BY_ID_AND_INSPARGPID: '/rest/v2/policy/vb/loadVBPolicyByPolicyIdAndInsuredParGpId',
	SERVER_LESS_QUERY_ES_: 'https://fc-search-index-csms-server-es-wcgxwhvhks.cn-shanghai.fcapp.run',

	// 搜索历史
	CREATE_SEARCH_HISTORY: '/rest/client/product/search/history/create',
	QUERY_SEARCH_HISTORY: '/rest/client/product/search/history/list',
	DELETE_SEARCH_HISTORY: '/rest/client/product/search/history/delete', // get 无参数

	SEND_EMAIL: '/rest/v2/account/settings/sendMail', // POST 设置是否接受邮件 {"enableSendEmail":true}


	FAMILY_MEMBER_LIST_ACCOUNT_ID: '/rest/v1/familyMember/list/accountId',
	FAMILY_MEMBER_UPDATE: '/rest/v1/familyMember/update',
	FAMILY_MEMBER_DELETE: '/rest/v1/familyMember/delete',
	FAMILY_MEMBER_INFO_MEMBER_ID: '/rest/v1/familyMember/find',

	GET_RENEW_POLICYIDS: '/rest/v1/policy/my/getRenewablePolicyIds',
	GET_VENDOR_URL : '/rest/v2/policy/getVendorUrl/:vendorCode/:policyId/:type',


	GET_OPENID_BY_CODE: '/rest/v2/weixin/getOpenIdByCode', // GET 根据微信code获取openId {code:"", keyword:"mej"}
	WEIXIN_SIGNIN: '/rest/v2/weixin/signIn', // POST 微信登录{"keyword":"mej","openId":""}
	CHECK_STATUS_BY_OPENID: '/rest/v2/weixin/checkStatusByOpenId', // POST 检查绑定登录及用户状态 {keyword, openId}
	OPENID_BINDING_BY_PASSWORD: '/rest/v2/weixin/bindingByPassword', // POST 密码绑定{"tenantCode":"MEJ","keyword":"mej","mobile":"","password":"!","openId":""}
	OPENID_BINDING_BY_SMS: '/rest/v2/weixin/bindingBySms', // POST 验证码绑定{"tenantCode": "MEJ","keyword": "mej","mobile": "","validateCode": "","openId": ""}
	UNBIND_BY_OPENID: '/rest/v2/weixin/unbindByOpenId', // POST 解绑{"keyword":"mej","openId":"","validateCode":""}
	GET_MOBILE_BY_OPENID: '/rest/v2/weixin/getMobileByOpenId', // GET 根据openId获取手机号{"keyword":"mej","openId":""}
	UNBIND_BY_MOBILE: '/rest/v2/weixin/unbindByMobile', // POST 手机号直接解绑{"keyword":"mej","tenantCode":"","mobile":""}

} as APIS;
