import crypto from 'crypto';
//@ts-ignore
import kitx from 'kitx';
import querystring from 'querystring';
import url from 'url';
import { Consts, Envs, Utils } from '.';


interface FcToken {
    accountId: string;
    accessKeyID: string;
    accessKeySecret: string;
    region: string;
    appVersion: string;
}

export type FcKipOptions = {
    actUrl?: string;
    forQuote?: true;
}

class FcKit {
    private fcToken: FcToken;

    constructor() {
        this.fcToken = {
            accessKeyID: this.getFCAccessKeyID(),
            accessKeySecret: this.getFCAccessKeySecret(),
            accountId: this.getFCAccountId(),
            region: this.getFCRegion(),
            appVersion: '2016-08-15',
        }
    }

    private getCsmsFcHost() {
        if (Envs.getEnvValue('ENV_NAME') === 'PROD') {
            //EKANGJI适配预生产
            if (new RegExp(`^${Envs.getEnvValue('EKANGJI_YL_TOATOC_HOST_FOR_PREPROD')}$`).test(window.location.hostname) || new RegExp(`^${Envs.getEnvValue('EKANGJI_HB_TOATOC_HOST_FOR_PREPROD')}$`).test(window.location.hostname)) {
                return Envs.getEnvValue('FC_CSMS_HOST_FOR_PREPROD') || 'csms-fc.ebaocloud.com.cn'
            }
        }
        return Envs.getEnvValue('FC_CSMS_HOST') || 'csms-fc.ebaocloud.com.cn';
    }

    private getCsmsFcHostQuote() {
        if (Envs.getEnvValue('ENV_NAME') === 'PROD') {
            //EKANGJI适配预生产
            if (new RegExp(`^${Envs.getEnvValue('EKANGJI_YL_TOATOC_HOST_FOR_PREPROD')}$`).test(window.location.hostname) || new RegExp(`^${Envs.getEnvValue('EKANGJI_HB_TOATOC_HOST_FOR_PREPROD')}$`).test(window.location.hostname)) {
                return Envs.getEnvValue('FC_CSMS_HOST_QUOTE_FOR_PREPROD') || 'hmb.ekangji.com'
            }
        }
        return Envs.getEnvValue('FC_CSMS_HOST_QUOTE') || 'hmb.ekangji.com';
    }

    private getFCAccessKeyID() {
        if (Envs.getEnvValue('ENV_NAME') === 'PROD') {
            //EKANGJI适配预生产
            if (new RegExp(`^${Envs.getEnvValue('EKANGJI_TOATOC_HOST_FOR_PREPROD')}$`).test(window.location.hostname)) {
                return Envs.getEnvValue('FC_ACCESSKEY_ID_FOR_PREPROD') || 'LTAI5tBoKyQjh4zcPKdKevqy'
            }
        }
        return Envs.getEnvValue('FC_ACCESSKEY_ID') || 'LTAI5tBoKyQjh4zcPKdKevqy'
    }

    private getFCAccessKeySecret() {
        if (Envs.getEnvValue('ENV_NAME') === 'PROD') {
            //EKANGJI适配预生产
            if (new RegExp(`^${Envs.getEnvValue('EKANGJI_YL_TOATOC_HOST_FOR_PREPROD')}$`).test(window.location.hostname) || new RegExp(`^${Envs.getEnvValue('EKANGJI_HB_TOATOC_HOST_FOR_PREPROD')}$`).test(window.location.hostname)) {
                return Envs.getEnvValue('FC_ACCESSKEY_SECRET_FOR_PREPROD') || ''
            }
        }
        return Envs.getEnvValue('FC_ACCESSKEY_SECRET') || ''
    }

    private getFCAccountId() {
        if (Envs.getEnvValue('ENV_NAME') === 'PROD') {
            //EKANGJI适配预生产
            if (new RegExp(`^${Envs.getEnvValue('EKANGJI_YL_TOATOC_HOST_FOR_PREPROD')}$`).test(window.location.hostname) || new RegExp(`^${Envs.getEnvValue('EKANGJI_HB_TOATOC_HOST_FOR_PREPROD')}$`).test(window.location.hostname)) {
                return Envs.getEnvValue('FC_ACCOUNT_ID_FOR_PREPROD') || '1625142560600834'
            }
        }
        return Envs.getEnvValue('FC_ACCOUNT_ID') || '1625142560600834'
    }

    private getFCRegion() {
        if (Envs.getEnvValue('ENV_NAME') === 'PROD') {
            //EKANGJI适配预生产
            if (new RegExp(`^${Envs.getEnvValue('EKANGJI_YL_TOATOC_HOST_FOR_PREPROD')}$`).test(window.location.hostname) || new RegExp(`^${Envs.getEnvValue('EKANGJI_HB_TOATOC_HOST_FOR_PREPROD')}$`).test(window.location.hostname)) {
                return Envs.getEnvValue('FC_REGION_FOR_PREPROD') || 'cn-shanghai'
            }
        }
        return Envs.getEnvValue('FC_REGION') || 'cn-shanghai'
    }

    private buildResponseHeaders = (response: Response): any => {
        const headers: any = {};
        response.headers.forEach((value: string, key: string) => {
            headers[key] = value;
        });
        return headers;
    };

    private buildResponseData = (response: Response, body: any): any => {
        return {
            headers: this.buildResponseHeaders(response),
            body: body,
            status: response.status,
            statusText: response.statusText
        };
    };

    private rejectWithError = (error: Error, reject: any): void => {
        reject({
            headers: {},
            body: {
                errors: [
                    {
                        code: Consts.FETCH_ERROR,
                        description: `${error.name}: ${error.message}`
                    }
                ]
            },
            status: 0,
            statusText: 'Fetch error occurred.',
            // keep original error here
            error: error
        });
    };

    /**
     * 处理响应数据
     *
     * @param {Response} response
     * @param {string} responseType
     * @param {function} successHandler 解析成功处理器
     * @param {function} failHandler 解析失败处理器
     */
    private handleResponse = (
        response: Response,
        responseType: string | null,
        successHandler: any,
        failHandler: any
    ): void => {
        const contentType = response.headers.get('content-type') || '';
        if (contentType.includes('json')) {
            responseType = 'json';
        } else if (contentType.includes('xml') || contentType.includes('text') || contentType.includes('html')) {
            responseType = 'text';
        }

        let extractor = null;
        switch (responseType) {
            case 'text':
                extractor = Response.prototype.text;
                break;
            case 'blob':
                extractor = Response.prototype.blob;
                break;
            default:
                // json
                extractor = Response.prototype.json;
                break;
        }
        extractor
            .call(response)
            .then(data => {
                successHandler(this.buildResponseData(response, data));
            })
            .catch(ex => {
                this.rejectWithError(ex, failHandler);
            });
    };

    getFcPath(fileName: string, queryString: { [propName: string]: any; }, fcOptions?: FcKipOptions) {
        const { forQuote = false } = fcOptions || {};
        if (!new RegExp('^/proxy/').test(fileName)) {
            fileName = `/proxy${fileName}`
        }
        const host = `${this.fcToken.accountId}.${this.fcToken.region}.fc.aliyuncs.com`;
        const filepath = fileName.split('/').map(segment => encodeURIComponent(segment)).join('/');

        let url = `https://${host}/${this.fcToken.appVersion}${filepath}`;

        if (queryString && Object.keys(queryString).length > 0) {
            url = `${url}?${querystring.stringify(queryString)}`;
        }

        let newUrl = url;

        const getFcHost = (forQuote: boolean) => {
            if (forQuote == true) {
                return `${this.getCsmsFcHostQuote()}`;
            }
            return `${this.getCsmsFcHost()}`;
        }

        if (!Utils.isBlank(this.getCsmsFcHost())) {
            if (new RegExp(`^${this.fcToken.accountId}`).test(host)) {
                newUrl = newUrl.replace(`${host}/${this.fcToken.appVersion}/proxy/`, `${getFcHost(forQuote)}/`)
            }
        }
        return newUrl;
    }


    async post(blob: any, fileName: string, queryString: { [propName: string]: any; }, fcOptions?: FcKipOptions) {
        if (!new RegExp('^/proxy/').test(fileName)) {
            fileName = `/proxy${fileName}`
        }
        return new Promise(async (resolve, reject) => {
            await this.doPost(blob, fileName, queryString, fcOptions)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }

    async get(fileName: string, queryString: { [propName: string]: any; }, fcOptions?: FcKipOptions) {
        if (!new RegExp('^/proxy/').test(fileName)) {
            fileName = `/proxy${fileName}`
        }
        return new Promise(async (resolve, reject) => {
            await this.doGet(fileName, queryString, fcOptions)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }

    doGet = async (fileName: string, query: { [propName: string]: any; }, fcOptions: FcKipOptions | undefined) => {
        let { actUrl } = fcOptions || {};
        return new Promise((resolve, reject) => {
            const host = `${this.fcToken.accountId}.${this.fcToken.region}.fc.aliyuncs.com`;
            const filepath = fileName.split('/').map(segment => encodeURIComponent(segment)).join('/');

            let url = `https://${host}/${this.fcToken.appVersion}${filepath}`;

            if (query && Object.keys(query).length > 0) {
                url = `${url}?${querystring.stringify(query)}`;
                if (!Utils.isBlank(actUrl)) {
                    actUrl = `${actUrl}?${querystring.stringify(query)}`;
                }
            }

            var now = new Date();
            const headers = {
                accept: 'application/json',
                date: now.toUTCString(),
                host: host,
                'x-fc-date': now.toUTCString(),
                'x-fc-account-id': this.fcToken.accountId,
            } as any;

            headers['content-type'] = 'application/json';

            let newUrl = url;

            let path = `/${this.fcToken.appVersion}${filepath}`;
            if (!Utils.isBlank(this.getCsmsFcHost())) {
                if (new RegExp(`^${this.fcToken.accountId}`).test(host)) {
                    newUrl = newUrl.replace(`${host}/${this.fcToken.appVersion}/proxy/`, `${this.getCsmsFcHost()}/`)
                    if (new RegExp(`^/${this.fcToken.appVersion}`).test(path)) {
                        path = path.replace(`/${this.fcToken.appVersion}/proxy/`, '/');
                    }
                }
            }

            const sign = this.getSignature(this.fcToken.accessKeyID, this.fcToken.accessKeySecret, 'GET', path, headers, query);
            headers.authorization = sign;

            fetch(newUrl, {
                method: 'GET',
                headers,
            }).then(response => {
                this.handleResponse(response, 'json', resolve, reject);
            }).catch(error => {
                this.rejectWithError(error, reject);
            });
        });
    };


    doPost = async (body: any, fileName: string, query: { [propName: string]: any; }, fcOptions: FcKipOptions | undefined) => {
        let { actUrl } = fcOptions || {};
        return new Promise((resolve, reject) => {
            const host = `${this.fcToken.accountId}.${this.fcToken.region}.fc.aliyuncs.com`;
            const filepath = fileName.split('/').map(segment => encodeURIComponent(segment)).join('/');

            let url = `https://${host}/${this.fcToken.appVersion}${filepath}`;

            if (query && Object.keys(query).length > 0) {
                url = `${url}?${querystring.stringify(query)}`;
                if (!Utils.isBlank(actUrl)) {
                    actUrl = `${actUrl}?${querystring.stringify(query)}`;
                }
            }

            var now = new Date();
            const headers = {
                accept: 'application/json',
                date: now.toUTCString(),
                host: host,
                'x-fc-date': now.toUTCString(),
                'x-fc-account-id': this.fcToken.accountId,
            } as any;
            var buff = Buffer.from(JSON.stringify(body), 'utf8');
            const digest = kitx.md5(buff, 'hex');
            const md5 = Buffer.from(digest, 'utf8').toString('base64');

            headers['content-type'] = 'application/json';
            headers['content-length'] = buff.length;
            headers['content-md5'] = md5

            let newUrl = url;

            let path = `/${this.fcToken.appVersion}${filepath}`;
            if (!Utils.isBlank(this.getCsmsFcHost())) {
                if (new RegExp(`^${this.fcToken.accountId}`).test(host)) {
                    newUrl = newUrl.replace(`${host}/${this.fcToken.appVersion}/proxy/`, `${this.getCsmsFcHost()}/`)
                    if (new RegExp(`^/${this.fcToken.appVersion}`).test(path)) {
                        path = path.replace(`/${this.fcToken.appVersion}/proxy/`, '/');
                    }
                }
            }

            const sign = this.getSignature(this.fcToken.accessKeyID, this.fcToken.accessKeySecret, 'POST', path, headers, query);
            headers.authorization = sign;

            fetch(newUrl, {
                method: 'POST',
                headers,
                body: JSON.stringify(body || {})
            }).then(response => {
                this.handleResponse(response, 'json', resolve, reject);
            }).catch(error => {
                this.rejectWithError(error, reject);
            });
        });
    };

    /**
     * 获得Header 签名
     *
     * @param {String} accessKeyID
     * @param {String} accessKeySecret
     * @param {String} method : GET/POST/PUT/DELETE/HEAD
     * @param {String} path
     * @param {json} headers : {headerKey1 : 'headValue1'}
     */
    getSignature(accessKeyID: string, accessKeySecret: string, method: string, path: string, headers: any, queries: any) {
        var stringToSign = this.composeStringToSign(method, path, headers, queries);
        var sign = this.signString(stringToSign, accessKeySecret);
        return `FC ${accessKeyID}:${sign}`;
    }

    signString(source: string, secret: string) {
        console.log(source);
        console.log(secret);
        const buff = crypto.createHmac('sha256', secret).update(source, 'utf8').digest();
        //@ts-ignore
        return Buffer.from(buff, 'binary').toString('base64');
    }

    buildCanonicalHeaders(headers: any, prefix: string) {
        var list = [];
        var keys = Object.keys(headers);

        var fcHeaders = {} as any;
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];

            var lowerKey = key.toLowerCase().trim();
            if (lowerKey.startsWith(prefix)) {
                list.push(lowerKey);
                fcHeaders[lowerKey] = headers[key];
            }
        }
        list.sort();

        var canonical = '';
        for (let i = 0; i < list.length; i++) {
            const key = list[i];
            canonical += `${key}:${fcHeaders[key]}\n`;
        }

        return canonical;
    }

    composeStringToSign(method: string, path: string, headers: any, queries: any) {
        const contentMD5 = headers['content-md5'] || '';
        const contentType = headers['content-type'] || '';
        const date = headers['date'];
        const signHeaders = this.buildCanonicalHeaders(headers, 'x-fc-');


        const u = url.parse(path);
        //@ts-ignore
        const pathUnescaped = decodeURIComponent(u.pathname);
        var str = `${method}\n${contentMD5}\n${contentType}\n${date}\n${signHeaders}${pathUnescaped}`;

        if (queries) {
            var params = [] as any[];
            Object.keys(queries).forEach(function (key) {
                var values = queries[key];
                var type = typeof values;
                if (type === 'string') {
                    params.push(`${key}=${values}`);
                    return;
                }
                if (Array.isArray(values)) {
                    queries[key].forEach(function (value: string) {
                        params.push(`${key}=${value}`);
                    });
                }
            });
            params.sort();
            str += '\n' + params.join('\n');
        }
        return str;
    }

}

export const FcKitHelper = (() => {
    let instance = null as any;
    return {
        getInstance: function () {
            if (!instance) {
                instance = new FcKit();
            }
            return instance;
        }
    }
})();

