import { StyledPropsTheme } from '@my-types';

import * as styledComponents from 'styled-components';

const {
	default: styled,
	css,
	createGlobalStyle,
	keyframes,
	ThemeProvider,
	ThemeContext,
	ThemeConsumer
} = styledComponents as styledComponents.ThemedStyledComponentsModule<
	StyledPropsTheme
>;

const { isStyledComponent, withTheme } = styledComponents;


const hexToRgba = (hex: string) => {
	const rgba = [];
	hex = hex.replace('#', '').padEnd(6, 'F');
	for (let i = 0; i < hex.length; i += 2) {
		rgba.push(parseInt(hex.slice(i, i + 2), 16));
	}
	return rgba;
}

/**
 * 颜色加深变浅方法
 */
const colorLightenDarken = (col: string, amt: number) => {
	let usePound = false;
	if (col[0] == "#") {
		col = col.slice(1);
		usePound = true;
	}
	let num = parseInt(col, 16);
	let r = (num >> 16) + amt;
	if (r > 255) r = 255;
	else if (r < 0) r = 0;
	let b = ((num >> 8) & 0x00FF) + amt;
	if (b > 255) b = 255;
	else if (b < 0) b = 0;
	let g = (num & 0x0000FF) + amt;
	if (g > 255) g = 255;
	else if (g < 0) g = 0;
	return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
}

function px2Rem(pxValue: any) {
	//默认手机开发模式375模式开发，如果设计稿给的750模版,需要自动转换成375，及除以2之后换算
	const DESIGN_WIDTH = 375;
	//@ts-ignore
	const fontSize = window.getComputedStyle(document.documentElement)['font-size'] as any;
	let fontSizeValue = 100;
	// 针对template literals
	if (Array.isArray(fontSize)) {
		fontSizeValue = fontSize[0];
	}

	// console.log(fontSizeValue);
	//宽度越大，值越大
	let ratio = document.documentElement.clientWidth * 1.00 / DESIGN_WIDTH;

	// 针对template literals
	if (Array.isArray(pxValue)) {
		pxValue = pxValue[0];
	}

	pxValue = parseFloat(pxValue);

	return (pxValue * ratio) / (fontSizeValue * 1.00) + 'rem';
}


export {
	css,
	px2Rem,
	hexToRgba,
	colorLightenDarken,
	createGlobalStyle,
	keyframes,
	ThemeProvider,
	ThemeContext,
	ThemeConsumer
};
export { isStyledComponent, withTheme };
export default styled;
